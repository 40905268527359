import {mapGetters, mapMutations} from "vuex";

export default {
  name: 'thanks',
  computed: {
    ...mapGetters({
      popupThanks: 'system/popupThanks',
    })
  },
  methods: {
    ...mapMutations({
      changeShowThanksPopup: `system/SHOW_POPUP_THANKS`,
    })
  },
  destroyed() {
  }
}
